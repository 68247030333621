import { IconType } from "react-icons";

// Add these type definitions at the top of the file
export type MediaItem = MediaItemProps;
export enum SectionPreset {
    Default = 'Default',
    Problem = 'Problem',
    Solution = 'Solution',
    Feature = 'Feature',
    Testimonial = 'Testimonial',
    CTA = 'CTA',
    UseCases = 'UseCases'
}

export interface FooterLinkProps {
    text: string;
    href: string;
}

export interface FooterColumnProps {
    title: string;
    links: FooterLinkProps[];
}

export interface FooterProps {
    filterCopyrightText?: (text: string) => string;
    filterClassNames?: (classNames: Record<string, string>) => Record<string, string>;
    columns: FooterColumnProps[];
    columnCount?: number;
}

export interface CTASectionPresetProps {
    filterTitle?: (title: string) => string;
    filterDescription?: (description: string) => string;
    filterButtonText?: (buttonText: string) => string;
    filterButtonLink?: (buttonLink: string) => string;
    filterClassNames?: (classNames: Record<string, string>) => Record<string, string>;
}

export interface FAQSectionPresetProps {
    filterTitle?: (title: string) => string;
    filterFAQs?: (faqs: Array<FAQProps>) => Array<FAQProps>;
    filterFAQ?: (faq: FAQProps) => FAQProps;
    filterClassNames?: (classNames: Record<string, string>) => Record<string, string>;
}

export interface PricingPlanProps {
    name: string;
    price: string;
    features: string[];
    highlighted: boolean;
    benefitsTitle: string;
    benefits: string[];
    ctaText: string;
    ctaSubtext?: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface NotReadySectionProps {
    title: string;
    features: string[];
    ctaText: string;
    ctaLink: string;
}

export interface PricingSectionPresetProps {
    filterPlans?: (plans: PricingPlanProps[]) => PricingPlanProps[];
    filterPlan?: (plan: PricingPlanProps) => PricingPlanProps;
    filterNotReadySection?: (section: NotReadySectionProps) => NotReadySectionProps;
}

export interface TestimonialsSectionPresetProps {
    filterTitle?: (title: string) => string;
    filterTestimonials?: (testimonials: TestimonialProps[]) => TestimonialProps[];
    filterTestimonial?: (testimonial: TestimonialProps) => TestimonialProps;
}


export interface FeatureProps {
    icon: IconType;
    title: string;
    description: string;
    color: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface UseCasePresetProps {
    title: string;
    description: string;
    videoSrc: string;
    placeholderSrc: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface TestimonialPresetProps {
    quote: string;
    author: string;
    role: string;
    icon: IconType;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface UseCaseProps {
    icon: IconType;
    title: string;
    description: string;
    example: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface TestimonialProps {
    quote: string;
    author: string;
    image?: string;
    icon?: IconType;
    role?: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}


export interface FeatureGridSectionPresetProps {
    filterTitle?: (title: string) => string;
    filterFeatures?: (features: FeatureProps[]) => FeatureProps[];
    filterFeature?: (feature: FeatureProps) => FeatureProps;
}

export interface UseCasesSectionPresetProps {
    filterUseCases?: (useCases: UseCaseWithVideoProps[]) => UseCaseWithVideoProps[];
    filterUseCase?: (useCase: UseCaseWithVideoProps) => UseCaseWithVideoProps;
}

export interface HeroSectionPresetProps {
    filterPreDynamic?: (preDynamic: string) => string;
    filterDynamicPhrases?: (dynamicPhrases: string[]) => string[];
    filterPostDynamic?: (postDynamic: string) => string;
    filterCtaText?: (ctaText: string) => string;
    filterCtaLink?: (ctaLink: string) => string;
    filterMedia?: (media: MediaItemProps) => MediaItemProps;
    filterClassNames?: (classNames: Partial<Record<ClassNameKeys, string>>) => Partial<Record<ClassNameKeys, string>>;
}

export interface ProblemStatementSectionPresetProps {
    filterTitle?: (title: string) => string;
    filterSubtitle?: (subtitle: string) => string;
    filterProblems?: (problems: ProblemProps[]) => ProblemProps[];
    filterProblem?: (problem: ProblemProps) => ProblemProps;
}

export interface ProblemProps {
    icon: IconType;
    title: string;
    description: string;
}

export interface SolutionProps {
    icon: IconType;
    title: string;
    description: string;
}

export interface CategoryProps {
    name: string;
    solutions: SolutionProps[];
}

export interface SolutionStatementSectionPresetProps {
    filterTitle?: (title: string) => string;
    filterSubtitle?: (subtitle: string) => string;
    filterCategories?: (categories: CategoryProps[]) => CategoryProps[];
    filterCategory?: (category: CategoryProps) => CategoryProps;
    filterSolution?: (solution: SolutionProps) => SolutionProps;
}

export enum ClassNameKeys {
    Container,
    Section,
    Heading,
    Subheading,
    Text,
    List,
    ListItem,
    Card,
    Button,
    Icon,
    Grid,
    NotReady
}

export interface FAQProps {
    question: string;
    answer: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface MediaItemProps {
    type: 'image' | 'video';
    src: string;
    placeholderSrc?: string; // Optional placeholder image for videos
}

export interface HeroProps {
    preDynamic: string;
    dynamicPhrases: string[];
    postDynamic: string;
    ctaText: string;
    ctaLink: string;
    media: MediaItemProps;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface ProblemStatementProps {
    title: string;
    subtitle: string;
    problems: ProblemProps[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface FeatureGridProps {
    features: FeatureProps[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface CTAProps {
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface TimelineEventProps {
    title: string;
    description: string;
    date: string;
}

export interface TimelineProps {
    events: TimelineEventProps[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface StatProps {
    label: string;
    value: string | number;
    icon: IconType;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface CustomCarouselProps {
    items: React.ReactNode[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface RatingProps {
    value: number;
    maxValue?: number;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface SolutionStatementProps {
    title: string;
    subtitle: string;
    categories: CategoryProps[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface UseCaseWithVideoProps {
    title: string;
    description: string;
    videoSrc: string;
    placeholderSrc: string;
    isReversed?: boolean;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface UseCaseWithVideoSectionProps {
    useCases: UseCaseWithVideoProps[];
    classNames?: Partial<Record<ClassNameKeys, string>>;
}

export interface SectionProps {
    title: string;
    children: React.ReactNode;
    classNames?: Partial<Record<ClassNameKeys, string>>;
    preset?: SectionPreset;
}

export interface PricingPlansProps {
    plans: PricingPlanProps[];
    notReadySection?: NotReadySectionProps;
    classNames?: Partial<Record<ClassNameKeys, string>>;
}