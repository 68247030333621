// Client Component
'use client';

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FaCheckCircle, FaChevronRight, FaClock, FaQuoteLeft, FaStar, FaUser} from 'react-icons/fa';
import Link from 'next/link';
import Image from 'next/image';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {useMediaQuery} from 'react-responsive';
import {
    SectionProps,
    FeatureProps,
    UseCaseProps,
    TestimonialProps,
    PricingPlanProps,
    PricingPlansProps,
    FAQProps,
    HeroProps,
    ProblemStatementProps,
    FeatureGridProps,
    CTAProps,
    TimelineProps,
    StatProps,
    CustomCarouselProps,
    RatingProps,
    SolutionStatementProps,
    UseCaseWithVideoProps,
    UseCaseWithVideoSectionProps,
    ClassNameKeys,
    MediaItemProps,
    CategoryProps,
    SolutionProps,
    SectionPreset,
    MediaItem
} from '@/types/homepage';
import { __ } from '@/utils/translationUtils';



export const Section: React.FC<SectionProps> = ({title, children, classNames = {}, preset = SectionPreset.Default}) => {
    const presetStyles: Record<SectionPreset, string> = {
        [SectionPreset.Default]: '',
        [SectionPreset.Problem]: 'bg-gradient-to-br from-red-300 to-red-600 text-white py-20',
        [SectionPreset.Solution]: 'bg-gradient-to-br from-blue-500 to-purple-600 text-white py-20',
        [SectionPreset.Feature]: 'bg-gradient-to-br from-green-400 to-teal-500 text-white py-20',
        [SectionPreset.Testimonial]: 'bg-gradient-to-br from-yellow-400 to-orange-500 text-white py-20',
        [SectionPreset.CTA]: 'bg-gradient-to-br from-indigo-500 to-purple-700 text-white py-20',
        [SectionPreset.UseCases]: 'bg-gradient-to-br from-teal-400 to-cyan-500 text-white py-20'
    };

    const presetHeadingStyles: Record<SectionPreset, string> = {
        [SectionPreset.Default]: 'text-gray-800 dark:text-gray-100',
        [SectionPreset.Problem]: 'text-white',
        [SectionPreset.Solution]: 'text-white',
        [SectionPreset.Feature]: 'text-white',
        [SectionPreset.Testimonial]: 'text-white',
        [SectionPreset.CTA]: 'text-white',
        [SectionPreset.UseCases]: 'text-white'
    };

    return (
        <section
            className={`px-4 py-20 sm:px-6 lg:px-8 ${presetStyles[preset]} ${classNames[ClassNameKeys.Section] || ''}`}>
            <h2 className={`text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-center transition-colors duration-300 ${presetHeadingStyles[preset]} ${classNames[ClassNameKeys.Heading] || ''}`}>
                {title}
            </h2>
            <div className="max-w-7xl mx-auto">
                {children}
            </div>
        </section>
    );
};

export const Feature: React.FC<FeatureProps> = ({icon: Icon, title, description, color, classNames = {}}) => (
    <div
        className={`bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-6 transform transition duration-500 hover:scale-105 overflow-hidden ${classNames[ClassNameKeys.Card] || ''}`}>
        <div className="flex items-center mb-4 flex-wrap">
            <Icon
                className={`text-3xl sm:text-4xl ${color} mr-3 mb-2 sm:mb-0 ${classNames[ClassNameKeys.Icon] || ''}`}/>
            <h3 className={`text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200 transition-colors duration-300 ${classNames[ClassNameKeys.Subheading] || ''}`}>{title}</h3>
        </div>
        <p className={`text-sm sm:text-base text-gray-600 dark:text-gray-400 transition-colors duration-300 line-clamp-3 ${classNames[ClassNameKeys.Text] || ''}`}>{description}</p>
    </div>
);

export const UseCase: React.FC<UseCaseProps> = ({icon: Icon, title, description, example, classNames = {}}) => (
    <div
        className={`bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-6 flex flex-col h-full ${classNames[ClassNameKeys.Card] || ''}`}>
        <div className="flex items-center mb-4 flex-wrap">
            <Icon
                className={`text-2xl sm:text-3xl text-indigo-600 dark:text-indigo-400 mr-3 mb-2 sm:mb-0 ${classNames[ClassNameKeys.Icon] || ''}`}/>
            <h3 className={`text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200 transition-colors duration-300 ${classNames[ClassNameKeys.Subheading] || ''}`}>{title}</h3>
        </div>
        <p className={`text-sm sm:text-base text-gray-600 dark:text-gray-400 mb-4 flex-grow transition-colors duration-300 ${classNames[ClassNameKeys.Text] || ''}`}>{description}</p>
        <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-3 mt-auto transition-colors duration-300">
            <p className={`text-xs sm:text-sm text-gray-700 dark:text-gray-300 italic ${classNames[ClassNameKeys.Text] || ''}`}>{example}</p>
        </div>
    </div>
);

export const TestimonialSection: React.FC<TestimonialProps> = ({ quote, author, image, icon: Icon = FaUser, role, classNames = {} }) => (<></>);
// export const TestimonialSection: React.FC<TestimonialProps> = ({ quote, author, image, icon: Icon = FaUser, role, classNames = {} }) => (
//     <div
//         className={`bg-gradient-to-br from-indigo-500 to-purple-600 dark:from-indigo-800 dark:to-purple-900 rounded-3xl shadow-2xl p-3 sm:p-8 flex flex-col h-full transform hover:scale-105 transition-all duration-300 ${classNames[ClassNameKeys.Card] || ''}`}>
//         <div className="flex-grow relative">
//             <FaQuoteLeft
//                 className="text-xl sm:text-5xl text-white opacity-25 absolute top-0 left-0 transform -translate-x-1 -translate-y-1 sm:-translate-x-2 sm:-translate-y-2"/>
//             <p className={`text-sm sm:text-xl text-white leading-relaxed mb-2 sm:mb-6 italic relative z-10 ${classNames[ClassNameKeys.Text] || ''}`}>"{quote}"</p>
//         </div>
//         <div className="flex items-center mt-2 sm:mt-4">
//             <div className="w-8 h-8 sm:w-16 sm:h-16 relative mr-2 sm:mr-4 overflow-hidden rounded-full border-2 sm:border-4 border-white shadow-lg">
//                 {image ? (
//                     <Image
//                         src={image}
//                         alt={author}
//                         layout="fill"
//                         objectFit="cover"
//                         onError={(e) => {
//                             const target = e.target as HTMLImageElement;
//                             target.src = '/placeholder-avatar.png';
//                         }}
//                     />
//                 ) : (
//                     <div className="w-full h-full flex items-center justify-center bg-indigo-200 dark:bg-indigo-700">
//                         <Icon className="text-lg sm:text-3xl text-indigo-600 dark:text-indigo-300"/>
//                     </div>
//                 )}
//             </div>
//             <div>
//                 <p className={`text-xs sm:text-lg text-white font-bold ${classNames[ClassNameKeys.Text] || ''}`}>{author}</p>
//                 {role && <p className={`text-xs sm:text-sm text-indigo-200 ${classNames[ClassNameKeys.Text] || ''}`}>{role}</p>}
//             </div>
//         </div>
//     </div>
// );

export const PricingPlan: React.FC<PricingPlanProps> = ({ name, price, features, highlighted = false, benefitsTitle, benefits = [], ctaText, ctaSubtext, classNames = {} }) => (
    <div
        className={`bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-6 flex flex-col h-full ${
            highlighted ? 'ring-2 ring-indigo-600 transform scale-105' : ''
        } transition-all duration-300 ${classNames[ClassNameKeys.Card] || ''}`}
    >
        <h3 className={`text-xl sm:text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200 transition-colors duration-300 ${classNames[ClassNameKeys.Subheading] || ''}`}>
            {name}
        </h3>
        <p className={`text-3xl sm:text-4xl font-bold mb-6 text-indigo-600 dark:text-indigo-400 transition-colors duration-300 ${classNames[ClassNameKeys.Text] || ''}`}>
            {price}<span className="text-sm sm:text-base font-normal text-gray-600 dark:text-gray-400">/month</span>
        </p>
        <ul className={`mb-8 flex-grow space-y-2 ${classNames[ClassNameKeys.List] || ''}`}>
            {features.map((feature, index) => (
                <li key={index}
                    className={`flex items-start text-sm sm:text-base text-gray-600 dark:text-gray-400 transition-colors duration-300 ${classNames[ClassNameKeys.ListItem] || ''}`}>
                    <FaCheckCircle className="text-green-500 mr-2 mt-1 flex-shrink-0"/>
                    <span>{feature}</span>
                </li>
            ))}
        </ul>
        {benefits.length > 0 && (
            <div className="mb-6">
                <h4 className={`text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200 ${classNames[ClassNameKeys.Subheading] || ''}`}>
                    {benefitsTitle}
                </h4>
                <ul className={`space-y-2 ${classNames[ClassNameKeys.List] || ''}`}>
                    {benefits.map((benefit, index) => (
                        <li key={index}
                            className={`flex items-start text-sm text-gray-600 dark:text-gray-400 ${classNames[ClassNameKeys.ListItem] || ''}`}>
                            <FaStar className="text-yellow-500 mr-2 mt-1 flex-shrink-0"/>
                            <span>{benefit}</span>
                        </li>
                    ))}
                </ul>
            </div>
        )}
        <div className="flex flex-col items-center">
            <button
                className={`py-2 px-4 rounded-full font-bold text-sm sm:text-base transition-colors duration-300 ${
                    highlighted ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                } ${classNames[ClassNameKeys.Button] || ''}`}
            >
                {ctaText}
            </button>
            {ctaSubtext && (
                <p className={`mt-2 text-xs text-gray-500 dark:text-gray-400 ${classNames[ClassNameKeys.Text] || ''}`}>
                    {ctaSubtext}
                </p>
            )}
        </div>
    </div>
);

export const PricingPlansSection: React.FC<PricingPlansProps> = ({ plans, notReadySection, classNames = {} }) => (
    <div className={`flex flex-col gap-8 ${classNames[ClassNameKeys.Container] || ''}`}>
        <div className={`grid ${plans.length === 1 ? 'max-w-md mx-auto' : `grid-cols-1 md:grid-cols-${Math.min(plans.length, 3)}`} gap-8 ${classNames[ClassNameKeys.Grid] || ''}`}>
            {plans.map((plan, index) => (
                <PricingPlan key={index} {...plan} classNames={classNames} />
            ))}
        </div>
        {notReadySection && (
            <div className={`mt-8 bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden mx-auto ${classNames[ClassNameKeys.NotReady] || ''}`} style={{ maxWidth: '384px' }}>
                <div className="p-4">
                    <h4 className={`text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200 text-center ${classNames[ClassNameKeys.Subheading] || ''}`}>
                        {notReadySection.title}
                    </h4>
                    <ul className={`space-y-2 mb-4 ${classNames[ClassNameKeys.List] || ''}`}>
                        {notReadySection.features.map((item, index) => (
                            <li key={index}
                                className={`flex items-center text-sm text-gray-600 dark:text-gray-400 ${classNames[ClassNameKeys.ListItem] || ''}`}>
                                <FaCheckCircle className="text-green-500 mr-2 flex-shrink-0"/>
                                <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                    <div className="text-center">
                        <a
                            href={notReadySection.ctaLink}
                            className={`inline-block py-2 px-4 rounded-full text-sm font-medium transition-colors duration-300 bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 ${classNames[ClassNameKeys.Button] || ''}`}
                        >
                            {notReadySection.ctaText}
                        </a>
                    </div>
                </div>
            </div>
        )}
    </div>
);

export const FAQSection: React.FC<FAQProps> = ({question, answer, classNames = {}}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`bg-white dark:bg-gray-800 rounded-lg shadow transition-all duration-300 ${classNames[ClassNameKeys.Card] || ''}`}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full text-left focus:outline-none p-4 sm:p-6"
            >
                <h3 className={`text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-100 transition-colors duration-300 flex justify-between items-center ${classNames[ClassNameKeys.Subheading] || ''}`}>
                    {question}
                    <span className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''} ml-2`}>▼</span>
                </h3>
                {isOpen && (
                    <p className={`mt-2 text-sm text-gray-600 dark:text-gray-400 transition-all duration-300 ${classNames[ClassNameKeys.Text] || ''}`}>
                        {answer}
                    </p>
                )}
            </button>
        </div>
    );
};


interface RenderMediaItemProps {
    item: MediaItem;
    index?: number;
}

export const RenderMediaItem: React.FC<RenderMediaItemProps> = ({ item, index }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (item.type === 'video' && videoRef.current) {
            videoRef.current.addEventListener('loadeddata', () => setIsVideoLoaded(true));
            videoRef.current.addEventListener('ended', () => {
                if (videoRef.current) {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play();
                }
            });
        }
    }, [item.type]);

    return (
        <div className="relative w-full h-full flex items-center justify-center">
            {item.type === 'image' ? (
                <Image
                    src={item.src}
                    alt={`Hero image ${index !== undefined ? index + 1 : ''}`}
                    layout="fill"
                    objectFit="cover"
                    className="rounded-3xl"
                />
            ) : (
                <div className="relative rounded-3xl overflow-hidden shadow-2xl w-full aspect-[9/16]">
                    <div className="absolute inset-0 bg-gradient-to-br from-purple-600 to-indigo-600 opacity-75 rounded-3xl"></div>
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1/3 h-6 bg-black rounded-b-2xl"></div>
                    <div className="relative w-full h-full">
                        {item.placeholderSrc && !isVideoLoaded && (
                            <Image
                                src={item.placeholderSrc}
                                alt={`Video placeholder ${index !== undefined ? index + 1 : ''}`}
                                layout="fill"
                                objectFit="cover"
                                className="rounded-[24px]"
                            />
                        )}
                        <video
                            ref={videoRef}
                            src={item.src}
                            className={`absolute inset-0 w-full h-full object-cover rounded-[24px] ${isVideoLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="auto"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const HeroSection: React.FC<HeroProps> = ({
    ctaText,
    ctaLink,
    media,
    preDynamic,
    dynamicPhrases,
    postDynamic,
    classNames = {}
}) => {
    const [dynamicPhrase, setDynamicPhrase] = useState(dynamicPhrases[0]);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const phraseIndex = useRef(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTransitioning(true);
            setTimeout(() => {
                phraseIndex.current = (phraseIndex.current + 1) % dynamicPhrases.length;
                setDynamicPhrase(dynamicPhrases[phraseIndex.current]);
                setIsTransitioning(false);
            }, 500);
        }, 3000);

        return () => clearInterval(interval);
    }, [dynamicPhrases]);

    const renderDynamicPhrase = () => (
        <div className="relative">
            <h1 
                className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600 transition-all duration-500 ease-in-out ${isTransitioning ? 'opacity-0 transform translate-y-full' : 'opacity-100 transform translate-y-0'}`}
                style={{ lineHeight: '1.2' }}
            >
                <span className="inline-block animate-expand-vertical">
                    {dynamicPhrase.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < dynamicPhrase.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </span>
            </h1>
        </div>
    );

    return (
        <div className={`relative overflow-hidden bg-gray-900 ${classNames[ClassNameKeys.Container] || ''}`}>
            <div className="absolute inset-0 bg-[url('/images/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 lg:py-32">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="lg:w-3/5 lg:pr-12 mb-12 lg:mb-0 text-center lg:text-left">
                        <h2 className={`text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 ${classNames[ClassNameKeys.Subheading] || ''}`}>
                            {preDynamic}
                        </h2>
                        {renderDynamicPhrase()}
                        <h3 className={`text-3xl md:text-4xl lg:text-5xl font-bold text-white mt-6 mb-8 ${classNames[ClassNameKeys.Text] || ''}`}>
                            {postDynamic}
                        </h3>
                        <Link href={ctaLink}
                              className={`inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-gray-900 bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-yellow-500 hover:to-orange-600 md:py-4 md:text-lg md:px-10 transition duration-300 transform hover:scale-105 ${classNames[ClassNameKeys.Button] || ''}`}>
                            {ctaText}
                        </Link>
                    </div>
                    <div className="lg:w-2/5 relative z-10">
                        <div className="w-full max-w-sm mx-auto">
                            <RenderMediaItem item={media as MediaItem}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ProblemStatementSection: React.FC<ProblemStatementProps> = ({title, subtitle, problems, classNames = {}}) => (
    <Section
        title={title}
        preset={SectionPreset.Problem}
        classNames={{
            ...classNames,
            [ClassNameKeys.Section]: `bg-[#f34064] ${classNames[ClassNameKeys.Section] || ''}`,
        }}
    >
        <p className={`text-base sm:text-lg md:text-xl lg:text-2xl text-center mb-6 sm:mb-8 md:mb-10 lg:mb-12 max-w-3xl mx-auto text-white ${classNames[ClassNameKeys.Text] || ''}`}>
            {subtitle}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4 md:gap-6 lg:gap-8">
            {problems.map((problem, index) => (
                <div key={index}
                     className="bg-white rounded-lg p-3 sm:p-4 md:p-5 lg:p-6 shadow-lg transform transition duration-500 hover:scale-105">
                    <div className="flex items-center mb-2 sm:mb-3 md:mb-4">
                        <div className="flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-md bg-[#f34064] text-white mr-2 sm:mr-3 md:mr-4">
                            <problem.icon className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 object-contain"/>
                        </div>
                        <h3 className={`text-base sm:text-lg md:text-xl font-semibold text-gray-800 ${classNames[ClassNameKeys.Subheading] || ''}`}>
                            {problem.title}
                        </h3>
                    </div>
                    <p className={`text-xs sm:text-sm md:text-base text-gray-600 ${classNames[ClassNameKeys.Text] || ''}`}>
                        {problem.description}
                    </p>
                </div>
            ))}
        </div>
    </Section>
);

export const FeatureGridSection: React.FC<FeatureGridProps> = ({features, classNames = {}}) => (
    <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ${classNames[ClassNameKeys.Container] || ''}`}>
        {features.map((feature, index) => (
            <Feature key={index} {...feature} classNames={classNames}/>
        ))}
    </div>
);

export const CTASection: React.FC<CTAProps> = ({title, description, buttonText, buttonLink, classNames = {}}) => (
    <div className={`bg-indigo-700 ${classNames[ClassNameKeys.Container] || ''}`}>
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h2 className={`text-3xl font-extrabold text-white sm:text-4xl ${classNames[ClassNameKeys.Heading] || ''}`}>
                <span className="block">{title}</span>
            </h2>
            <p className={`mt-4 text-lg leading-6 text-indigo-200 ${classNames[ClassNameKeys.Text] || ''}`}>
                {description}
            </p>
            <Link href={buttonLink || '#'}
                  className={`mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto ${classNames[ClassNameKeys.Button] || ''}`}>
                {buttonText}
            </Link>
        </div>
    </div>
);

export const Timeline: React.FC<TimelineProps> = ({events, classNames = {}}) => (
    <div className={`relative ${classNames[ClassNameKeys.Container] || ''}`}>
        <div className="absolute top-0 bottom-0 left-1/2 w-0.5 bg-gray-200 dark:bg-gray-700"></div>
        {events.map((event, index) => (
            <div key={index}
                 className={`relative mb-8 flex items-center ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
                <div
                    className={`w-5 h-5 rounded-full bg-indigo-600 absolute left-1/2 transform -translate-x-1/2 ${classNames[ClassNameKeys.Icon] || ''}`}></div>
                <div
                    className={`w-5/12 p-4 rounded-lg shadow-md bg-white dark:bg-gray-800 ${classNames[ClassNameKeys.Card] || ''}`}>
                    <h3 className={`text-lg font-semibold mb-1 text-gray-800 dark:text-gray-200 ${classNames[ClassNameKeys.Subheading] || ''}`}>{event.title}</h3>
                    <p className={`text-sm text-gray-600 dark:text-gray-400 mb-2 ${classNames[ClassNameKeys.Text] || ''}`}>{event.description}</p>
                    <span
                        className={`text-xs text-indigo-600 dark:text-indigo-400 ${classNames[ClassNameKeys.Text] || ''}`}>{event.date}</span>
                </div>
            </div>
        ))}
    </div>
);

export const Stat: React.FC<StatProps> = ({label, value, icon: Icon, classNames = {}}) => (
    <div className={`p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg ${classNames[ClassNameKeys.Card] || ''}`}>
        <div className="flex items-center justify-between mb-2">
            <Icon className={`text-3xl text-indigo-600 dark:text-indigo-400 ${classNames[ClassNameKeys.Icon] || ''}`}/>
            <span
                className={`text-2xl font-bold text-gray-800 dark:text-gray-200 ${classNames[ClassNameKeys.Text] || ''}`}>{value}</span>
        </div>
        <p className={`text-sm text-gray-600 dark:text-gray-400 ${classNames[ClassNameKeys.Text] || ''}`}>{label}</p>
    </div>
);

export const CustomCarousel: React.FC<CustomCarouselProps> = ({items, classNames = {}}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    return (
        <div className={`relative ${classNames[ClassNameKeys.Container] || ''}`}>
            <div className="overflow-hidden">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{transform: `translateX(-${currentIndex * 100}%)`}}
                >
                    {items.map((item, index) => (
                        <div key={index} className="w-full flex-shrink-0">
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white dark:bg-gray-800 rounded-full p-2 shadow-md"
            >
                <FaChevronRight className="text-gray-600 dark:text-gray-400 transform rotate-180"/>
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white dark:bg-gray-800 rounded-full p-2 shadow-md"
            >
                <FaChevronRight className="text-gray-600 dark:text-gray-400"/>
            </button>
        </div>
    );
};

export const Rating: React.FC<RatingProps> = ({value, maxValue = 5, classNames = {}}) => (
    <div className={`flex items-center ${classNames[ClassNameKeys.Container] || ''}`}>
        {[...Array(maxValue)].map((_, index) => (
            <FaStar
                key={index}
                className={`${
                    index < value ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'
                } ${classNames[ClassNameKeys.Icon] || ''}`}
            />
        ))}
    </div>
);

export const SolutionStatementSection: React.FC<SolutionStatementProps> = ({title, subtitle, categories, classNames = {}}) => {
    const [activeCategory, setActiveCategory] = useState<string>('All');
    const [visibleCount, setVisibleCount] = useState(9);
    const [isMounted, setIsMounted] = useState(false);
    const loadMoreRef = useRef<HTMLDivElement>(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const allCategories = useMemo(() => ['All', ...categories.map(c => c.name)], [categories]);

    const allSolutions = useMemo(() => categories.flatMap(c => c.solutions), [categories]);

    const shuffledAllSolutions = useMemo(() => {
        if (!isMounted) return allSolutions;
        return activeCategory === 'All'
            ? [...allSolutions].sort(() => Math.random() - 0.5)
            : allSolutions;
    }, [allSolutions, activeCategory, isMounted]);

    const visibleSolutions = useMemo(() => {
        if (!isMounted) return [];
        return activeCategory === 'All'
            ? shuffledAllSolutions
            : categories.find(c => c.name === activeCategory)?.solutions || [];
    }, [categories, activeCategory, shuffledAllSolutions, isMounted]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 9);
        setShouldScroll(true);
    };

    const hasMore = visibleCount < (activeCategory === 'All' ? allSolutions.length : categories.find(c => c.name === activeCategory)?.solutions.length || 0);

    useEffect(() => {
        if (shouldScroll && loadMoreRef.current) {
            loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            setShouldScroll(false);
        }
    }, [visibleCount, shouldScroll]);

    return (
        <Section
            title={title}
            preset={SectionPreset.Solution}
            classNames={classNames}
        >
            <p className={`text-xl sm:text-2xl text-center mb-6 max-w-3xl mx-auto ${classNames[ClassNameKeys.Text] || ''}`}>
                {subtitle}
            </p>
            <div className="mb-8 overflow-x-auto scrollbar-hide">
                <div className={`flex ${isMobile ? 'flex-nowrap' : 'flex-wrap justify-center gap-4'} pb-2`}>
                    {allCategories.map((category) => (
                        <button
                            key={category}
                            onClick={() => {
                                setActiveCategory(category);
                                setVisibleCount(9);
                            }}
                            className={`px-4 py-2 text-sm md:text-base font-semibold rounded-full transition-all duration-300 ease-in-out whitespace-nowrap ${
                                activeCategory === category
                                    ? 'bg-indigo-600 text-white shadow-lg transform scale-105'
                                    : 'bg-white bg-opacity-10 text-gray-200 hover:bg-opacity-20 hover:text-white'
                            }`}
                        >
                            {category}
                        </button>
                    ))}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
                {isMounted && visibleSolutions.slice(0, visibleCount).map((solution, index) => (
                    <div key={index}
                         className="bg-white bg-opacity-10 rounded-lg p-3 md:p-6 backdrop-filter backdrop-blur-lg transition-all duration-500 hover:scale-105 hover:shadow-xl"
                         style={{
                             opacity: 0,
                             animation: `fadeIn 0.5s ease-out ${index * 0.01}s forwards`
                         }}
                    >
                        <div className="flex items-center mb-2 md:mb-4">
                            <div
                                className="flex items-center justify-center h-8 w-8 md:h-12 md:w-12 rounded-md bg-gradient-to-r from-pink-500 to-orange-500 text-white mr-2 md:mr-4 transition-all duration-300 hover:rotate-12">
                                <solution.icon className="h-4 w-4 md:h-6 md:w-6"/>
                            </div>
                            <h3 className={`text-base md:text-xl font-semibold ${classNames[ClassNameKeys.Subheading] || ''}`}>
                                {solution.title}
                            </h3>
                        </div>
                        <p className={`text-xs md:text-base text-gray-100 ${classNames[ClassNameKeys.Text] || ''}`}>
                            {solution.description}
                        </p>
                    </div>
                ))}
            </div>
            {hasMore && (
                <div ref={loadMoreRef} className="mt-8 text-center">
                    <button
                        onClick={loadMore}
                        className="bg-indigo-600 text-white px-6 py-3 rounded-full hover:bg-indigo-700 transition-all duration-300 transform hover:scale-105 shadow-lg"
                    >
                        {__("Load More")}
                    </button>
                </div>
            )}
            <style jsx>{`
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                .scrollbar-hide::-webkit-scrollbar {
                    display: none;
                }
                .scrollbar-hide {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
            `}</style>
        </Section>
    );
};

export const UseCaseWithVideo: React.FC<UseCaseWithVideoProps> = ({
    title,
    description,
    videoSrc,
    placeholderSrc,
    isReversed = false,
    classNames = {}
}) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', () => setIsVideoLoaded(true));
        }
    }, []);

    const ContentSection = () => (
        <div className="w-full lg:w-3/5 p-4 lg:p-8 flex flex-col justify-center">
            <h3 className={`text-3xl md:text-4xl lg:text-6xl font-bold mb-4 lg:mb-6 bg-gradient-to-r from-indigo-600 to-purple-700 text-transparent bg-clip-text ${classNames[ClassNameKeys.Subheading] || ''}`}>
                {title}
            </h3>
            <p className={`text-lg md:text-xl lg:text-2xl leading-relaxed text-gray-900 ${classNames[ClassNameKeys.Text] || ''}`}>
                {description}
            </p>
        </div>
    );

    const VideoSection = () => (
        <div className="w-full lg:w-2/5 p-4 lg:p-8 flex items-center justify-center">
            <div className="relative rounded-[30px] overflow-hidden shadow-2xl w-full max-w-[280px] lg:max-w-sm aspect-[9/16]">
                <div className="absolute inset-0 bg-black rounded-[30px]"></div>
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1/3 h-6 bg-black rounded-b-2xl"></div>
                <div className="relative w-full h-full">
                    {!isVideoLoaded && (
                        <Image
                            src={placeholderSrc}
                            alt="Video placeholder"
                            layout="fill"
                            objectFit="cover"
                            className="rounded-[24px]"
                        />
                    )}
                    <video
                        ref={videoRef}
                        src={videoSrc}
                        className={`absolute inset-0 w-full h-full object-cover rounded-[24px] ${isVideoLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className={`use-case-with-video ${classNames[ClassNameKeys.Container] || ''}`}>
            <div className={`flex flex-col lg:flex-row items-center ${isReversed ? 'lg:flex-row-reverse' : ''}`}>
                <ContentSection />
                <VideoSection />
            </div>
        </div>
    );
};

export const UseCaseWithVideoSection: React.FC<UseCaseWithVideoSectionProps> = ({
    useCases,
    classNames = {}
}) => (
    <Section
        title={__("Elevate Your Experience")}
        preset={SectionPreset.UseCases}
        classNames={classNames}
    >
        <div className="space-y-16 lg:space-y-24">
            {useCases.map((useCase, index) => (
                <UseCaseWithVideo
                    key={index}
                    {...useCase}
                    isReversed={index % 2 !== 0}
                    classNames={classNames}
                />
            ))}
        </div>
    </Section>
);