// Client Component
'use client';

import React from 'react';
import {FaAppleAlt, FaBaby, FaBed, FaBook, FaBookOpen, FaBrain, FaBriefcase, FaCalendarAlt, FaCamera, FaCar, FaChalkboard, FaChartLine, FaChartPie, FaChess, FaClock, FaCode, FaDog, FaDraftingCompass, FaDumbbell, FaExchangeAlt, FaExclamationTriangle, FaFileContract, FaFilm, FaGavel, FaGlobe, FaGlobeAmericas, FaGraduationCap, FaGuitar, FaHandshake, FaHardHat, FaHeadphones, FaHeartbeat, FaHome, FaKeyboard, FaLanguage, FaLaptopCode, FaLeaf, FaLightbulb, FaListUl, FaMicrophone, FaMicroscope, FaMoon, FaPalette, FaPen, FaPencilAlt, FaPencilRuler, FaPizzaSlice, FaPlane, FaPuzzlePiece, FaRobot, FaRocket, FaRunning, FaShoppingCart, FaShower, FaSitemap, FaStethoscope, FaStopwatch, FaTasks, FaTheaterMasks, FaTree, FaUpload, FaUserClock, FaUserFriends, FaUserGraduate, FaUserTie, FaUtensils, FaWater, FaWineGlass, FaWrench, FaYinYang} from 'react-icons/fa';
import { CTASection, FAQSection, FeatureGridSection, HeroSection, PricingPlan, PricingPlansSection, ProblemStatementSection, Section, SolutionStatementSection, TestimonialSection, UseCase, UseCaseWithVideoSection} from '@/components/ClientHomeComponents';
import {
    CTASectionPresetProps,
    CategoryProps, ClassNameKeys, FAQSectionPresetProps,
    FeatureProps,
    FeatureGridSectionPresetProps,
    HeroProps, HeroSectionPresetProps, MediaItem, NotReadySectionProps, PricingSectionPresetProps, PricingPlanProps,
    ProblemProps,
    ProblemStatementSectionPresetProps, SectionPreset, TestimonialsSectionPresetProps,
    SolutionStatementSectionPresetProps, TestimonialProps,
    UseCaseProps,
    UseCasesSectionPresetProps, UseCaseWithVideoProps,
    MediaItemProps
} from "@/types/homepage";
import { __, TranslationOptions } from '@/utils/translationUtils';
export const HeroSectionPreset: React.FC<HeroSectionPresetProps> = ({
    filterPreDynamic = (p) => p,
    filterDynamicPhrases = (d) => d,
    filterPostDynamic = (p) => p,
    filterCtaText = (c) => c,
    filterCtaLink = (l) => l,
    filterMedia = (m) => m,
    filterClassNames = (c) => c,
}) => {
    const defaultPreDynamic = __("NO MORE TYPING.", { source: 'core' });
    const defaultDynamicPhrases = [
        __("CREATE AI PROMPTS", { source: 'core' }),
        __("GENERATE PROMPTS", { source: 'core' }),
        __("EXPLAIN BUGS", { source: 'core' }),
        __("DEFINE FEATURES", { source: 'core' }),
        __("CAPTURE IDEAS", { source: 'core' }),
        __("DRAFT PAPERS", { source: 'core' }),
        __("PLAN DESIGNS", { source: 'core' }),
        __("RECORD PATIENT NOTES", { source: 'core' }),
        __("WRITE RECIPES", { source: 'core' }),
        __("CREATE TO-DO LISTS", { source: 'core' }),
        __("DOCUMENT ALGORITHMS", { source: 'core' }),
        __("COMPOSE ARTICLES", { source: 'core' }),
        __("CREATE THESES", { source: 'core' }),
        __("OUTLINE LESSONS", { source: 'core' }),
        __("DRAFT DOCUMENTS", { source: 'core' }),
        __("BRAINSTORM STRATEGIES", { source: 'core' }),
        __("DOCUMENT STORIES", { source: 'core' }),
        __("CRAFT ROADMAPS", { source: 'core' }),
        __("WRITE EMAILS", { source: 'core' }),
        __("DESIGN PROMPTS", { source: 'core' }),
        __("PROTOTYPE IDEAS", { source: 'core' }),
        __("OUTLINE LAUNCHES", { source: 'core' }),
        __("BRAINSTORM MODELS", { source: 'core' }),
        __("DRAFT PITCHES", { source: 'core' }),
        __("CREATE PERSONAS", { source: 'core' }),
        __("WRITE STORIES", { source: 'core' }),
        __("CRAFT BLOGS", { source: 'core' }),
        __("OUTLINE NOVELS", { source: 'core' }),
        __("CREATE CHARACTERS", { source: 'core' }),
        __("BRAINSTORM PLOTS", { source: 'core' }),
        __("DRAFT DIALOGUES", { source: 'core' }),
        __("COMPOSE POETRY", { source: 'core' }),
        __("WRITE COPY", { source: 'core' }),
        __("DOCUMENT CONCEPTS", { source: 'core' }),
        __("CAPTURE MEMORIES", { source: 'core' }),
        __("DICTATE NOTES", { source: 'core' }),
        __("LOG OBSERVATIONS", { source: 'core' }),
        __("BRAINSTORM STARTUPS", { source: 'core' }),
        __("DRAFT REPORTS", { source: 'core' }),
        __("PRACTICE PRONUNCIATION", { source: 'core' }),
        __("RECORD STORIES", { source: 'core' }),
        __("OUTLINE SPECS", { source: 'core' }),
        __("DOCUMENT FINDINGS", { source: 'core' }),
        __("TAKE NOTES", { source: 'core' }),
        __("PREPARE LESSONS", { source: 'core' }),
        __("DRAFT WILLS", { source: 'core' }),
        __("OUTLINE CHAPTERS", { source: 'core' })
    ];
    const defaultPostDynamic = __("WITH YOUR VOICE", { source: 'core' });
    const defaultCtaText = __("Try for free", { source: 'core' });
    const defaultCtaLink = "/pricing";
    const defaultMedia: MediaItemProps = {
        type: 'video',
        src: '/videos/capture-effortlessly.mp4',
        placeholderSrc: '/videos/capture-effortlessly.png'
    };
    const defaultClassNames: Partial<Record<ClassNameKeys, string>> = {
        [ClassNameKeys.Heading]: 'text-shadow-lg',
        [ClassNameKeys.Text]: 'text-shadow',
        [ClassNameKeys.Button]: 'shadow-lg hover:shadow-xl transition-shadow duration-300'
    };

    const filteredPreDynamic = filterPreDynamic(defaultPreDynamic);
    const filteredDynamicPhrases = filterDynamicPhrases(defaultDynamicPhrases);
    const filteredPostDynamic = filterPostDynamic(defaultPostDynamic);
    const filteredCtaText = filterCtaText(defaultCtaText);
    const filteredCtaLink = filterCtaLink(defaultCtaLink);
    const filteredMedia = filterMedia(defaultMedia);
    const filteredClassNames = filterClassNames(defaultClassNames);

    return (
        <HeroSection
            preDynamic={filteredPreDynamic}
            dynamicPhrases={filteredDynamicPhrases}
            postDynamic={filteredPostDynamic}
            ctaText={filteredCtaText}
            ctaLink={filteredCtaLink}
            media={filteredMedia}
            classNames={filteredClassNames}
        />
    );
};

export const ProblemStatementSectionPreset: React.FC<ProblemStatementSectionPresetProps> = ({
                                                                                    filterTitle = (t) => t,
                                                                                    filterSubtitle = (s) => s,
                                                                                    filterProblems = (p) => p,
                                                                                    filterProblem = (p) => p,
                                                                                }) => {
    const defaultTitle = __("Your Million-Dollar Ideas Are Slipping Away", { source: 'core' });
    const defaultSubtitle = __("Brilliant thoughts strike at the most inconvenient times. But by the time you can write them down, they're gone. Here's what you're up against:", { source: 'core' });

    const defaultProblems: ProblemProps[] = [
        {
            icon: FaLightbulb,
            title: __("Shower Thoughts Don't Survive the Towel", { source: 'core' }),
            description: __("Your most groundbreaking ideas emerge when you're least prepared. By the time you're dry, they've evaporated.", { source: 'core' })
        },
        {
            icon: FaClock,
            title: __("The 3 AM Eureka Moment: Gone by Breakfast", { source: 'core' }),
            description: __("Midnight inspirations feel world-changing, but come morning, you're left with a vague sense of lost opportunity.", { source: 'core' })
        },
        {
            icon: FaBrain,
            title: __("Mental Overload: The Creativity Killer", { source: 'core' }),
            description: __("Your brain is not a hard drive. Trying to store every idea is exhausting and blocks fresh thinking.", { source: 'core' })
        },
        {
            icon: FaExclamationTriangle,
            title: __("The High Cost of Forgotten Brilliance", { source: 'core' }),
            description: __("Each lost idea is a missed opportunity. How many game-changers have slipped through your fingers?", { source: 'core' })
        },
        {
            icon: FaKeyboard,
            title: __("Typing Can't Keep Up with Your Thoughts", { source: 'core' }),
            description: __("When inspiration strikes, even the fastest typists lose precious details in translation.", { source: 'core' })
        },
        {
            icon: FaUserFriends,
            title: __("Great Conversations, Forgotten Insights", { source: 'core' }),
            description: __("Engaging discussions spark amazing ideas, but without immediate capture, they fade into 'What were we talking about again?'", { source: 'core' })
        },
        {
            icon: FaGlobeAmericas,
            title: __("Lost in Translation: Complex Ideas Across Languages", { source: 'core' }),
            description: __("Communicating sophisticated concepts in foreign languages often results in diluted messages and misunderstandings.", { source: 'core' })
        },
        {
            icon: FaListUl,
            title: __("Chaotic Brain Dumps: Structure Eludes You", { source: 'core' }),
            description: __("Your mind races with ideas, but transforming that mental whirlwind into coherent, actionable lists feels impossible.", { source: 'core' })
        },
        {
            icon: FaBook,
            title: __("Cherished Memories Fade Away", { source: 'core' }),
            description: __("Life's precious moments slip through your fingers. Those vivid details you swore you'd never forget become hazy with time.", { source: 'core' })
        },
        {
            icon: FaUtensils,
            title: __("Family Recipes Lost to Time", { source: 'core' }),
            description: __("Grandma's secret ingredient remains a mystery. Culinary traditions vanish because they were never properly documented.", { source: 'core' })
        }
    ];

    const filteredTitle = filterTitle(defaultTitle);
    const filteredSubtitle = filterSubtitle(defaultSubtitle);
    const filteredProblems = filterProblems(defaultProblems).map(filterProblem);

    return (
        <ProblemStatementSection
            title={filteredTitle}
            subtitle={filteredSubtitle}
            problems={filteredProblems}
            classNames={{}}
        />
    );
};


export const SolutionStatementSectionPreset: React.FC<SolutionStatementSectionPresetProps> = ({
    filterTitle = (t) => t,
    filterSubtitle = (s) => s,
    filterCategories = (c) => c,
    filterCategory = (c) => c,
    filterSolution = (s) => s,
}) => {
    const defaultTitle = __("Sondeas: Your Personal Idea Vault and Productivity Powerhouse", { source: 'core' });
    const defaultSubtitle = __("Transforming everyday challenges into opportunities for growth, creativity, and efficiency.", { source: 'core' });

    const defaultCategories: CategoryProps[] = [
        {
            name: __("Professionals", { source: 'core' }),
            solutions: [
                {
                    icon: FaCar,
                    title: __("Commute Brilliance", { source: 'core' }),
                    description: __("Capture that million-dollar idea stuck in traffic without taking your eyes off the road.", { source: 'core' }),
                },
                {
                    icon: FaBriefcase,
                    title: __("Meeting Mastermind", { source: 'core' }),
                    description: __("Discreetly capture key points and action items during meetings without appearing distracted.", { source: 'core' }),
                },
                {
                    icon: FaPlane,
                    title: __("In-Flight Ideation", { source: 'core' }),
                    description: __("Brainstorm at 30,000 feet and have your ideas ready to implement upon landing.", { source: 'core' }),
                },
                {
                    icon: FaUserFriends,
                    title: __("Networking Ninja", { source: 'core' }),
                    description: __("Quickly jot down contact details and conversation highlights at bustling events.", { source: 'core' }),
                },
                {
                    icon: FaTasks,
                    title: __("Agile Assistant", { source: 'core' }),
                    description: __("Manage your product backlog, user stories, and sprint planning with voice commands.", { source: 'core' }),
                },
                {
                    icon: FaChartLine,
                    title: __("Data Insights", { source: 'core' }),
                    description: __("Record observations and insights during data analysis sessions without switching contexts.", { source: 'core' }),
                },
                {
                    icon: FaStethoscope,
                    title: __("Medical Rounds Recorder", { source: 'core' }),
                    description: __("Document patient observations and treatment plans hands-free during hospital rounds.", { source: 'core' }),
                },
                {
                    icon: FaHardHat,
                    title: __("Site Supervisor's Log", { source: 'core' }),
                    description: __("Keep detailed construction site logs and safety observations while on the move.", { source: 'core' }),
                },
                {
                    icon: FaGavel,
                    title: __("Legal Eagle", { source: 'core' }),
                    description: __("Dictate case notes and legal strategies between courtroom sessions.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Creatives", { source: 'core' }),
            solutions: [
                {
                    icon: FaBed,
                    title: __("3 AM Inspiration Saver", { source: 'core' }),
                    description: __("Whisper your midnight eureka moment without disturbing your partner or fumbling for a light.", { source: 'core' }),
                },
                {
                    icon: FaPalette,
                    title: __("Artist's Muse", { source: 'core' }),
                    description: __("Describe visual concepts or color palettes when your hands are covered in paint.", { source: 'core' }),
                },
                {
                    icon: FaTheaterMasks,
                    title: __("Actor's Ally", { source: 'core' }),
                    description: __("Memorize lines and record character insights between rehearsals.", { source: 'core' }),
                },
                {
                    icon: FaGuitar,
                    title: __("Musician's Melody Catcher", { source: 'core' }),
                    description: __("Hum that tune or describe that riff before it slips away, even mid-jam session.", { source: 'core' }),
                },
                {
                    icon: FaCamera,
                    title: __("Photographer's Perfect Shot", { source: 'core' }),
                    description: __("Describe lighting conditions or composition ideas between takes.", { source: 'core' }),
                },
                {
                    icon: FaPencilRuler,
                    title: __("Design Diary", { source: 'core' }),
                    description: __("Capture UI/UX ideas, color schemes, and user flow concepts while on the move.", { source: 'core' }),
                },
                {
                    icon: FaFilm,
                    title: __("Director's Cut", { source: 'core' }),
                    description: __("Record scene ideas, shot compositions, and editing notes on set.", { source: 'core' }),
                },
                {
                    icon: FaPen,
                    title: __("Writer's Block Buster", { source: 'core' }),
                    description: __("Dictate plot twists and character developments during writer's block moments.", { source: 'core' }),
                },
                {
                    icon: FaDraftingCompass,
                    title: __("Architect's Sketchpad", { source: 'core' }),
                    description: __("Verbalize building designs and spatial concepts while visiting potential sites.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Entrepreneurs & Business", { source: 'core' }),
            solutions: [
                {
                    icon: FaLightbulb,
                    title: __("Startup Spark", { source: 'core' }),
                    description: __("Capture and organize your next big startup idea, complete with market analysis and MVP features.", { source: 'core' }),
                },
                {
                    icon: FaPuzzlePiece,
                    title: __("Feature Brainstorming", { source: 'core' }),
                    description: __("Capture feature ideas and user feedback for your product roadmap anytime, anywhere.", { source: 'core' }),
                },
                {
                    icon: FaRocket,
                    title: __("Launch Checklist", { source: 'core' }),
                    description: __("Keep track of your product launch tasks and last-minute ideas without missing a beat.", { source: 'core' }),
                },
                {
                    icon: FaHandshake,
                    title: __("Deal Maker's Diary", { source: 'core' }),
                    description: __("Record negotiation points and deal terms discreetly during high-stakes meetings.", { source: 'core' }),
                },
                {
                    icon: FaFileContract,
                    title: __("HR Policy Planner", { source: 'core' }),
                    description: __("Draft and revise company policies on-the-go, ensuring nothing is overlooked.", { source: 'core' }),
                },
                {
                    icon: FaSitemap,
                    title: __("Org Chart Organizer", { source: 'core' }),
                    description: __("Update organizational structures and employee information on-the-go.", { source: 'core' }),
                },
                {
                    icon: FaChartPie,
                    title: __("Market Research Insights", { source: 'core' }),
                    description: __("Record competitor analysis and market trends while attending industry events.", { source: 'core' }),
                },
                {
                    icon: FaUserTie,
                    title: __("Investor Pitch Perfector", { source: 'core' }),
                    description: __("Refine your elevator pitch and funding proposals between meetings.", { source: 'core' }),
                },
                {
                    icon: FaGlobe,
                    title: __("Global Expansion Planner", { source: 'core' }),
                    description: __("Document international market entry strategies and cultural considerations during travels.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Students & Academics", { source: 'core' }),
            solutions: [
                {
                    icon: FaGraduationCap,
                    title: __("Lecture Lifesaver", { source: 'core' }),
                    description: __("Never miss a crucial detail in fast-paced lectures, even if you're not typing quickly enough.", { source: 'core' }),
                },
                {
                    icon: FaBookOpen,
                    title: __("Study Session Sidekick", { source: 'core' }),
                    description: __("Create voice-activated flashcards and summarize key points while reviewing materials.", { source: 'core' }),
                },
                {
                    icon: FaPen,
                    title: __("Essay Outliner", { source: 'core' }),
                    description: __("Brainstorm essay structures and capture citations while researching in the library.", { source: 'core' }),
                },
                {
                    icon: FaUserClock,
                    title: __("Time Management Tutor", { source: 'core' }),
                    description: __("Log study hours and create voice-activated reminders for assignment deadlines.", { source: 'core' }),
                },
                {
                    icon: FaChalkboard,
                    title: __("Teacher's Grading Guru", { source: 'core' }),
                    description: __("Record detailed feedback on assignments without the need for constant typing.", { source: 'core' }),
                },
                {
                    icon: FaUserGraduate,
                    title: __("Classroom Conductor", { source: 'core' }),
                    description: __("Manage classroom activities and student observations hands-free during lessons.", { source: 'core' }),
                },
                {
                    icon: FaMicroscope,
                    title: __("Lab Notes Logger", { source: 'core' }),
                    description: __("Document experiment observations and results hands-free in the laboratory.", { source: 'core' }),
                },
                {
                    icon: FaGlobeAmericas,
                    title: __("Field Research Recorder", { source: 'core' }),
                    description: __("Capture environmental observations and interview notes during fieldwork.", { source: 'core' }),
                },
                {
                    icon: FaBook,
                    title: __("Literature Review Assistant", { source: 'core' }),
                    description: __("Summarize key points and record your thoughts while speed-reading research papers.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Fitness & Wellness", { source: 'core' }),
            solutions: [
                {
                    icon: FaRunning,
                    title: __("Jogging Genius", { source: 'core' }),
                    description: __("Record your best thoughts during your morning run without breaking stride.", { source: 'core' }),
                },
                {
                    icon: FaDumbbell,
                    title: __("Gym Genius", { source: 'core' }),
                    description: __("Record workout ideas or track reps without interrupting your fitness flow.", { source: 'core' }),
                },
                {
                    icon: FaHeartbeat,
                    title: __("Wellness Journal", { source: 'core' }),
                    description: __("Log daily mood, energy levels, and health observations effortlessly.", { source: 'core' }),
                },
                {
                    icon: FaAppleAlt,
                    title: __("Nutrition Navigator", { source: 'core' }),
                    description: __("Track meals and nutritional intake with voice notes throughout the day.", { source: 'core' }),
                },
                {
                    icon: FaYinYang,
                    title: __("Meditation Moments", { source: 'core' }),
                    description: __("Capture post-meditation insights without disrupting your zen state.", { source: 'core' }),
                },
                {
                    icon: FaBed,
                    title: __("Sleep Cycle Tracker", { source: 'core' }),
                    description: __("Record sleep patterns and dream recollections upon waking.", { source: 'core' }),
                },
                {
                    icon: FaWater,
                    title: __("Hydration Helper", { source: 'core' }),
                    description: __("Set voice reminders for water intake and log your hydration habits.", { source: 'core' }),
                },
                {
                    icon: FaStopwatch,
                    title: __("Personal Best Recorder", { source: 'core' }),
                    description: __("Document your fitness milestones and personal records hands-free.", { source: 'core' }),
                },
                {
                    icon: FaLeaf,
                    title: __("Mindfulness Memos", { source: 'core' }),
                    description: __("Jot down mindfulness practices and self-care ideas as they come to you.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Hobbyists & Enthusiasts", { source: 'core' }),
            solutions: [
                {
                    icon: FaUtensils,
                    title: __("Kitchen Creativity", { source: 'core' }),
                    description: __("Document recipe tweaks and culinary inspirations with flour-covered hands.", { source: 'core' }),
                },
                {
                    icon: FaBook,
                    title: __("Bibliophile's Companion", { source: 'core' }),
                    description: __("Capture quotes and reflections while deep in a good book, without losing your place.", { source: 'core' }),
                },
                {
                    icon: FaWrench,
                    title: __("DIY Detective", { source: 'core' }),
                    description: __("Document measurements and project steps hands-free while knee-deep in a home renovation.", { source: 'core' }),
                },
                {
                    icon: FaTree,
                    title: __("Gardener's Green Thumb", { source: 'core' }),
                    description: __("Record planting schedules and care instructions while tending to your plants.", { source: 'core' }),
                },
                {
                    icon: FaChess,
                    title: __("Chess Master's Strategist", { source: 'core' }),
                    description: __("Analyze moves and record game strategies without disrupting your concentration.", { source: 'core' }),
                },
                {
                    icon: FaPizzaSlice,
                    title: __("Food Critic's Flavor Saver", { source: 'core' }),
                    description: __("Discreetly record detailed taste impressions and ambiance notes during meals.", { source: 'core' }),
                },
                {
                    icon: FaPalette,
                    title: __("Art Collector's Curator", { source: 'core' }),
                    description: __("Document artwork details and impressions while browsing galleries.", { source: 'core' }),
                },
                {
                    icon: FaWineGlass,
                    title: __("Wine Connoisseur's Notes", { source: 'core' }),
                    description: __("Capture tasting notes and vineyard experiences during wine tours.", { source: 'core' }),
                },
                {
                    icon: FaCamera,
                    title: __("Photography Expedition Log", { source: 'core' }),
                    description: __("Record shooting locations, camera settings, and composition ideas on photo walks.", { source: 'core' }),
                },
            ],
        },
        {
            name: __("Everyday Life", { source: 'core' }),
            solutions: [
                {
                    icon: FaShower,
                    title: __("Shower Thoughts Preserved", { source: 'core' }),
                    description: __("Finally, a way to save those brilliant ideas that always seem to strike mid-shampoo.", { source: 'core' }),
                },
                {
                    icon: FaBaby,
                    title: __("Parent's Little Helper", { source: 'core' }),
                    description: __("Keep track of pediatrician instructions or baby milestones while juggling a fussy infant.", { source: 'core' }),
                },
                {
                    icon: FaGlobeAmericas,
                    title: __("Traveler's Translator", { source: 'core' }),
                    description: __("Navigate foreign lands with ease, translating signs and menus in real-time.", { source: 'core' }),
                },
                {
                    icon: FaMoon,
                    title: __("Night Owl's Notebook", { source: 'core' }),
                    description: __("Capture late-night inspirations without disrupting your sleep cycle with bright screens.", { source: 'core' }),
                },
                {
                    icon: FaDog,
                    title: __("Pet Parent's Diary", { source: 'core' }),
                    description: __("Log behavior notes or vet instructions while keeping both hands free for your furry friend.", { source: 'core' }),
                },
                {
                    icon: FaShoppingCart,
                    title: __("Smart Shopper", { source: 'core' }),
                    description: __("Create and manage shopping lists hands-free while browsing store aisles.", { source: 'core' }),
                },
                {
                    icon: FaCar,
                    title: __("Road Trip Chronicler", { source: 'core' }),
                    description: __("Document travel memories and navigation notes without taking your hands off the wheel.", { source: 'core' }),
                },
                {
                    icon: FaHome,
                    title: __("Home Improvement Planner", { source: 'core' }),
                    description: __("Record room measurements and project ideas while inspecting your living space.", { source: 'core' }),
                },
                {
                    icon: FaCalendarAlt,
                    title: __("Life Organizer", { source: 'core' }),
                    description: __("Manage appointments, to-do lists, and reminders with voice commands throughout your day.", { source: 'core' }),
                },
            ],
        },
    ];

    const filteredTitle = filterTitle(defaultTitle);
    const filteredSubtitle = filterSubtitle(defaultSubtitle);
    const filteredCategories = filterCategories(defaultCategories).map(category => ({
        ...filterCategory(category),
        solutions: category.solutions.map(filterSolution)
    }));

    return (
        <SolutionStatementSection
            title={filteredTitle}
            subtitle={filteredSubtitle}
            categories={filteredCategories}
            classNames={{}}
        />
    );
};

export const FeatureGridSectionPreset: React.FC<FeatureGridSectionPresetProps> = ({
    filterTitle = (t) => t,
    filterFeatures = (f) => f,
    filterFeature = (f) => f,
}) => {
    const defaultTitle = __('Powerful Features, Effortless Use', { source: 'core' });
    const defaultFeatures: FeatureProps[] = [
        {
            icon: FaMicrophone,
            title: __("Speak Your Mind", { source: 'core' }),
            description: __("Just tap and talk. Your words become text instantly, with no time limit.", { source: 'core' }),
            color: "text-red-500"
        },
        {
            icon: FaHeadphones,
            title: __("Listen to Your Notes", { source: 'core' }),
            description: __("Convert any text to speech with 6 different voices.", { source: 'core' }),
            color: "text-blue-500"
        },
        {
            icon: FaGlobe,
            title: __("Translate Instantly", { source: 'core' }),
            description: __("Translate your notes into over 100 languages.", { source: 'core' }),
            color: "text-green-500"
        },
        {
            icon: FaRobot,
            title: __("AI-Powered Editing", { source: 'core' }),
            description: __("Use AI to fix syntax, terminology, and format.", { source: 'core' }),
            color: "text-purple-500"
        },
        {
            icon: FaListUl,
            title: __("Structured Text", { source: 'core' }),
            description: __("Convert any text into structured formats like lists or recipes.", { source: 'core' }),
            color: "text-yellow-500"
        },
        {
            icon: FaUserFriends,
            title: __("Character Rewrite", { source: 'core' }),
            description: __("Rewrite text as any public figure you choose.", { source: 'core' }),
            color: "text-pink-500"
        },
        {
            icon: FaUpload,
            title: __("Audio Upload", { source: 'core' }),
            description: __("Upload audio files for transcription, including podcasts.", { source: 'core' }),
            color: "text-indigo-500"
        },
        {
            icon: FaExchangeAlt,
            title: __("Conversation Mode", { source: 'core' }),
            description: __("Translate conversations in real-time for seamless communication.", { source: 'core' }),
            color: "text-teal-500"
        },
        {
            icon: FaKeyboard,
            title: __("Keyboard Shortcuts", { source: 'core' }),
            description: __("Quick commands for efficient note-taking across all platforms.", { source: 'core' }),
            color: "text-orange-500"
        },
    ];

    const filteredTitle = filterTitle(defaultTitle);
    const filteredFeatures = filterFeatures(defaultFeatures).map(filterFeature);

    return (
        <Section
            title={filteredTitle}
            preset={SectionPreset.Feature}>
            <FeatureGridSection
                features={filteredFeatures}
                classNames={{}}
            />
        </Section>
    );
};

export const UseCasesSectionPreset: React.FC<UseCasesSectionPresetProps> = ({filterUseCases = (u) => u, filterUseCase = (u) => u,}) => {
    const defaultUseCases: UseCaseWithVideoProps[] = [
        {
            title: __("Capture Ideas Effortlessly", { source: 'core' }),
            description: __("Transform your thoughts into text instantly with our powerful speech-to-text feature. Never let a brilliant idea slip away again.", { source: 'core' }),
            videoSrc: "/videos/capture-effortlessly.mp4",
            placeholderSrc: "/videos/capture-effortlessly.png",
        },
        {
            title: __("Structure Meeting Notes", { source: 'core' }),
            description: __("Convert your raw meeting notes into a well-organized format automatically. Save time and increase productivity with AI-powered note structuring.", { source: 'core' }),
            videoSrc: "/videos/meeting-notes.mp4",
            placeholderSrc: "/videos/meeting-notes.png",
        },
        {
            title: __("Translate Conversations in Real-Time", { source: 'core' }),
            description: __("Break language barriers with our conversation mode. Communicate effortlessly in foreign languages, perfect for travel or international business.", { source: 'core' }),
            videoSrc: "/videos/ask-directions-in-the-street.mp4",
            placeholderSrc: "/videos/ask-directions-in-the-street.png",
        },
        {
            title: __("Convert Notes to Recipes", { source: 'core' }),
            description: __("Turn your culinary notes into structured recipes instantly. Perfect for chefs, food bloggers, or anyone who loves cooking.", { source: 'core' }),
            videoSrc: "/videos/moms-cheesecake-recipe.mp4",
            placeholderSrc: "/videos/moms-cheesecake-recipe.png",
        },
    ];

    const filteredUseCases = filterUseCases(defaultUseCases).map(filterUseCase);

    return (
        <>
            {/* Use Cases */}
            <UseCaseWithVideoSection
                useCases={filteredUseCases}/>
        </>
    );
};

export const TestimonialsSectionPreset: React.FC<TestimonialsSectionPresetProps> = ({
    filterTitle = (t) => t,
    filterTestimonials = (t) => t,
    filterTestimonial = (t) => t,
}) => {
    const defaultTitle = __("Join Hundreds of Satisfied Users", { source: 'userContent' });
    const defaultTestimonials: TestimonialProps[] = [
        {
            quote: __("Sondeas has transformed how I manage my medical practice. I can now dictate patient notes instantly, saving hours each day.", { source: 'userContent' }),
            author: "Dr. Emily R.",
            role: __("General Practitioner", { source: 'userContent' }),
            icon: FaStethoscope,
        },
        {
            quote: __("As a journalist, Sondeas is my secret weapon. I can capture interviews accurately and quickly, even in noisy environments.", { source: 'userContent' }),
            author: "Miguel A.",
            role: __("Investigative Journalist", { source: 'userContent' }),
            icon: FaMicrophone,
        },
        {
            quote: __("Sondeas has revolutionized my creative process. I can brainstorm out loud and have all my ideas captured and organized instantly.", { source: 'userContent' }),
            author: "Giulia B.",
            role: __("Screenwriter", { source: 'userContent' }),
            icon: FaPencilAlt,
        },
        {
            quote: __("As a software developer, Sondeas helps me document my code and capture complex algorithms on-the-go. It's a game-changer for my productivity.", { source: 'userContent' }),
            author: "Wei L.",
            role: __("Software Developer", { source: 'userContent' }),
            icon: FaCode,
        },
        {
            quote: __("Sondeas streamlines our product development process. We can now capture and organize user feedback effortlessly during meetings and user testing sessions.", { source: 'userContent' }),
            author: "Hannah S.",
            role: __("Product Manager", { source: 'userContent' }),
            icon: FaTasks,
        },
        {
            quote: __("As an indie hacker, Sondeas is invaluable. I can brainstorm, plan, and document my startup ideas anywhere, anytime. It's like having a co-founder in my pocket.", { source: 'userContent' }),
            author: "Klaus W.",
            role: __("Indie Hacker", { source: 'userContent' }),
            icon: FaRocket,
        },
        {
            quote: __("Sondeas is a game-changer for VCs like me. I can capture and analyze startup pitches on-the-go, even when I'm back-to-back in meetings all day.", { source: 'userContent' }),
            author: "Sarah T.",
            role: __("Venture Capitalist", { source: 'userContent' }),
            icon: FaChartLine,
        },
        {
            quote: __("Sondeas is a lifesaver for my side project. I can capture ideas and code snippets during my commute, making the most of my limited spare time.", { source: 'userContent' }),
            author: "Carlos M.",
            role: __("Indie Maker", { source: 'userContent' }),
            icon: FaLaptopCode,
        },
        {
            quote: __("Working in four languages, Sondeas has become essential in my architectural practice. Its translation feature for client communications is a game-changer, ensuring no detail is lost across languages.", { source: 'userContent' }),
            author: "Olena S.",
            role: __("Architect", { source: 'userContent' }),
            icon: FaLanguage,
        }
    ];

    const filteredTitle = filterTitle(defaultTitle);
    const filteredTestimonials = filterTestimonials(defaultTestimonials).map(filterTestimonial);

    const testimonials = filteredTestimonials.map((testimonial, index) => (
        <TestimonialSection
            key={index} {...testimonial} />
    ));

    return (
        <Section
            title={filteredTitle}
            preset={SectionPreset.Testimonial}>
            <div
                className="grid md:grid-cols-3 gap-8">
                {testimonials}
            </div>
        </Section>
    );
};

export const PricingSectionPreset: React.FC<PricingSectionPresetProps> = ({ filterPlans = (p) => p, filterPlan = (p) => p, filterNotReadySection = (s) => s, }) => {
    const defaultPlans: PricingPlanProps[] = [
        {
            name: __("Pro", { source: 'billing' }),
            price: __("$5/month", { source: 'billing' }),
            features: [
                __("300 minutes of transcription per month", { source: 'billing' }),
                __("Voice recording and transcription", { source: 'billing' }),
                __("Upload audio files for transcription", { source: 'billing' }),
                __("Text-to-speech and audio generation", { source: 'billing' }),
                __("AI-powered text manipulation", { source: 'billing' }),
                __("Translation services for 100+ languages", { source: 'billing' }),
                __("Conversation mode (Alpha)", { source: 'billing' }),
                __("Cross-platform compatibility", { source: 'billing' }),
                __("Dark mode and light mode", { source: 'billing' }),
                __("Keyboard shortcuts", { source: 'billing' }),
                __("Usage tracking dashboard", { source: 'billing' }),
                __("Additional minutes available at discounted rates", { source: 'billing' }),
                __("Unused minutes do not expire", { source: 'billing' })
            ],
            highlighted: true,
            benefitsTitle: __("Why Go Pro?", { source: 'billing' }),
            benefits: [
                __('Unleash unlimited creativity without constraints', { source: 'billing' }),
                __('Access premium features to supercharge your productivity', { source: 'billing' }),
                __('Never worry about running out of minutes', { source: 'billing' }),
                __('Directly support a passionate solopreneur', { source: 'billing' }),
                __('Help shape the future of Sondeas with your feedback', { source: 'billing' }),
                __('Be part of an exclusive community of power users', { source: 'billing' })
            ],
            ctaText: __("Get Started", { source: 'billing' }),
            ctaSubtext: __("30-day money-back guarantee", { source: 'billing' })
        }
    ];

    const defaultNotReadySection: NotReadySectionProps = {
        title: __("Not Ready Yet?", { source: 'billing' }),
        features: [__('5 minutes of transcription', { source: 'billing' }), __('All features', { source: 'billing' })],
        ctaText: __("Try for Free", { source: 'billing' }),
        ctaLink: "/auth/signup/"
    };

    const filteredPlans = filterPlans(defaultPlans).map(filterPlan);
    const filteredNotReadySection = filterNotReadySection(defaultNotReadySection);

    return (
        <Section
            title={__("Simple Pricing, Powerful Value", { source: 'billing' })}
            preset={SectionPreset.Feature}
            classNames={{
                [ClassNameKeys.Section]: 'bg-gradient-to-br from-indigo-100 to-purple-100 dark:from-indigo-900 dark:to-purple-900',
                [ClassNameKeys.Heading]: 'text-indigo-800 dark:text-indigo-200',
                [ClassNameKeys.Container]: 'max-w-4xl mx-auto'
            }}
        >
            <PricingPlansSection
                plans={filteredPlans}
                notReadySection={filteredNotReadySection}
                classNames={{}}
            />
        </Section>
    );
};

export const FAQSectionPreset: React.FC<FAQSectionPresetProps> = ({
                                                          filterTitle = (t) => t,
                                                          filterFAQs = (f) => f,
                                                          filterFAQ = (f) => f,
                                                          filterClassNames = (c) => c,
                                                      }) => {
    const defaultTitle = __("Frequently Asked Questions", { source: 'info' });
    const defaultFAQs = [
        {
            question: __("How accurate is Sondeas' transcription?", { source: 'info' }),
            answer: __("Sondeas uses cutting-edge speech recognition technology, achieving industry-leading accuracy for clear audio. We continuously improve our technology to ensure the best possible transcription quality. Factors like background noise or accents may affect results, but we strive for optimal performance in various conditions.", { source: 'info' })
        },
        {
            question: __("Can I download my voice recordings and transcripts?", { source: 'info' }),
            answer: __("Absolutely! You can download all your voice recordings and transcripts at any time, free of charge. This includes both original recordings and AI-generated speech.", { source: 'info' })
        },
        {
            question: __("What happens if I need more transcription minutes?", { source: 'info' }),
            answer: __("If you need more transcription time, you can easily upgrade your plan or purchase additional minutes. All your existing notes and features remain accessible, and you can continue using Sondeas as a regular notes app even if you've used all your transcription minutes.", { source: 'info' })
        },
        {
            question: __("Can I access Sondeas from my computer and phone?", { source: 'info' }),
            answer: __("Yes! Sondeas is a progressive web app compatible with desktop computers, iOS, and Android devices. You can access it through any modern web browser or install it on your device for an app-like experience.", { source: 'info' })
        },
        {
            question: __("Is Sondeas available on iOS?", { source: 'info' }),
            answer: __("Absolutely! Sondeas works seamlessly on iOS devices. You can use it through Safari or install it as a progressive web app for a native app-like experience.", { source: 'info' })
        },
        {
            question: __("Are my notes private and secure?", { source: 'info' }),
            answer: __("Your privacy and data security are our top priorities. All your recordings and notes are encrypted, and we never share your data with third parties. Our web-based approach ensures more constrained permissions compared to native apps, adding an extra layer of security.", { source: 'info' })
        },
        {
            question: __("How does the conversation mode work?", { source: 'info' }),
            answer: __("Conversation mode enables real-time translation between two languages. Simply select the languages for each side of the conversation, speak, and Sondeas will transcribe, translate, and display the text in both languages. It's perfect for deep conversations in foreign languages.", { source: 'info' })
        },
        {
            question: __("Can I use Sondeas offline?", { source: 'info' }),
            answer: __("No, Sondeas cannot be used offline as it requires an internet connection for its core functionalities. However, we've designed it to be somewhat resilient to weak or inconsistent internet connections. With a poor connection, you can still create and manage notes, and even attempt transcriptions, though they may fail. Failed transcriptions can be retried later, or you can download recordings for processing when your connection improves. While Sondeas remains partially functional with unreliable connectivity, a stable internet connection is necessary for optimal performance and full feature access. We'd love to hear from you if offline functionality is important for your use case, as this feedback helps us prioritize future development. Please reach out to our support team to share your thoughts on offline usage.", { source: 'info' })
        },
        {
            question: __("How do I get started with Sondeas?", { source: 'info' }),
            answer: __("Getting started is easy! Sign up for a free trial, no credit card required. You'll have immediate access to all features, allowing you to experience the full power of Sondeas.", { source: 'info' })
        },
        {
            question: __("What languages does Sondeas support?", { source: 'info' }),
            answer: __("Sondeas supports over 100 languages for transcription and translation. Whether you're working with common languages or less frequently used ones, we've got you covered. Specifically: English, Chinese, German, Spanish, Russian, Korean, French, Japanese, Portuguese, Turkish, Polish, Catalan, Dutch, Arabic, Swedish, Italian, Indonesian, Hindi, Finnish, Vietnamese, Hebrew, Ukrainian, Greek, Malay, Czech, Romanian, Danish, Hungarian, Tamil, Norwegian, Thai, Urdu, Croatian, Bulgarian, Lithuanian, Latin, Maori, Malayalam, Welsh, Slovak, Telugu, Persian, Latvian, Bengali, Serbian, Azerbaijani, Slovenian, Kannada, Estonian, Macedonian, Breton, Basque, Icelandic, Armenian, Nepali, Mongolian, Bosnian, Kazakh, Albanian, Swahili, Galician, Marathi, Punjabi, Sinhala, Khmer, Shona, Yoruba, Somali, Afrikaans, Occitan, Georgian, Belarusian, Tajik, Sindhi, Gujarati, Amharic, Yiddish, Lao, Uzbek, Faroese, Haitian Creole, Pashto, Turkmen, Nynorsk, Maltese, Sanskrit, Luxembourgish, Myanmar, Tibetan, Tagalog, Malagasy, Assamese, Tatar, Hawaiian, Lingala, Hausa, Bashkir, Javanese, Sundanese, and Cantonese.", { source: 'info' })
        },
        {
            question: __("Can I share my notes with others?", { source: 'info' }),
            answer: __("Within Sondeas, your notes are private and can never be accessed by other users. However, if you wish to share specific content, you can use the share option to export and send information externally. This ensures that your notes remain secure within the app while still giving you the flexibility to share when needed.", { source: 'info' })
        },
        {
            question: __("Is there a limit to how much I can store?", { source: 'info' }),
            answer: __("With the Pro plan, you get unlimited storage for your notes and recordings. We believe in giving you the freedom to capture as many ideas as you need without worrying about space constraints.", { source: 'info' })
        }
    ];

    const defaultClassNames = {
        [ClassNameKeys.Section]: 'bg-gray-100 dark:bg-gray-900',
        [ClassNameKeys.Heading]: 'text-gray-800 dark:text-gray-100',
        [ClassNameKeys.Container]: 'max-w-4xl mx-auto',
        [ClassNameKeys.Card]: 'bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300',
        [ClassNameKeys.Subheading]: 'text-gray-800 dark:text-gray-200',
        [ClassNameKeys.Text]: 'text-gray-600 dark:text-gray-400'
    };

    const filteredTitle = filterTitle(defaultTitle);
    const filteredFAQs = filterFAQs(defaultFAQs).map(filterFAQ);
    const filteredClassNames = filterClassNames(defaultClassNames);

    return (
        <Section
            title={filteredTitle}
            preset={SectionPreset.Default}
            classNames={filteredClassNames}>
            <div
                className="space-y-6">
                {filteredFAQs.map((faq, index) => (
                    <FAQSection
                        key={index} {...faq}
                        classNames={filteredClassNames}/>
                ))}
            </div>
        </Section>
    );
};
export const CTASectionPreset: React.FC<CTASectionPresetProps> = ({
    filterTitle = (t) => t,
    filterDescription = (d) => d,
    filterButtonText = (bt) => bt,
    filterButtonLink = (bl) => bl,
    filterClassNames = (c) => c,
}) => {
    const defaultTitle = __("Ready to Unleash Your Voice?", { source: 'uiComponents' });
    const defaultDescription = __("Start your free trial today. No credit card required.", { source: 'uiComponents' });
    const defaultButtonText = __("Start Free Trial", { source: 'uiComponents' });
    const defaultButtonLink = "/pricing";

    const defaultClassNames = {
        [ClassNameKeys.Container]: 'bg-indigo-600 rounded-lg shadow-lg p-4 sm:p-6 md:p-8 lg:p-12 my-8 sm:my-12 md:my-16 mx-2 sm:mx-4 md:mx-8 lg:mx-16',
        [ClassNameKeys.Heading]: 'text-white text-xl sm:text-2xl md:text-3xl mb-2 sm:mb-3',
        [ClassNameKeys.Text]: 'text-sm sm:text-base md:text-lg text-indigo-100 mb-4 sm:mb-6',
        [ClassNameKeys.Button]: 'inline-block bg-white text-indigo-600 font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-indigo-100 transition duration-300 text-sm sm:text-base'
    };

    const filteredTitle = filterTitle(defaultTitle);
    const filteredDescription = filterDescription(defaultDescription);
    const filteredButtonText = filterButtonText(defaultButtonText);
    const filteredButtonLink = filterButtonLink(defaultButtonLink);
    const filteredClassNames = filterClassNames(defaultClassNames);

    return (
        <CTASection
            title={filteredTitle}
            description={filteredDescription}
            buttonText={filteredButtonText}
            buttonLink={filteredButtonLink}
            classNames={filteredClassNames}
        />
    );
};